import * as React from "react"
import OutlinedInput from "../../../../components/form/OutlinedInput"
import FormFieldProps from "../../../../components/form/FormFieldProps"
import ReferUsFormType from "../../../../model/ReferUsFormType"

const LastNameField = ({ onChange, onBlur, state, validate }: FormFieldProps<ReferUsFormType>) => {
  return (
    <OutlinedInput
      name="lastName"
      onChange={onChange}
      value={state.lastName}
      label="Your last name"
      validate={validate}
      onBlur={onBlur}
      type="text"
      isValid={LastNameField.isValid(state)}
      marginBottom="30px"
    />
  )
}

LastNameField.isValid = ({ lastName }: ReferUsFormType) => {
  return Boolean(lastName)
}

export default LastNameField
