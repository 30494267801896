import * as React from "react"
import TextArea from "../../../../components/form/TextArea"
import ReferUsFormType from "../../../../model/ReferUsFormType"

interface MessageFieldProps {
  onChange: (name: string, value: number | string | boolean) => void
  state: ReferUsFormType
}

const MessageField = ({ onChange, state }: MessageFieldProps) => {
  return (
    <TextArea
      name="message"
      onChange={onChange}
      label="Message for your referral"
      height="211px"
      value={state.message}
      validate
      isValid
      disabled={false}
      marginBottom="25px"
    />
  )
}

export default MessageField
