import * as React from "react"
import styled from "styled-components"
import { lightBlack, otherBorderGrey, skyBlue, white, rouge } from "../../../../constants/colors"
import { gillFont } from "../../../../constants/fonts"
import ReferUsFormType from "../../../../model/ReferUsFormType"

const Container = styled.div`
  width: 100%;
`

const Select = styled.select<{ valid: boolean }>`
  width: 100%;
  background: ${white};
  border-radius: 5px;
  border: 1px solid ${({ valid }) => (valid ? otherBorderGrey : rouge)};
`

const Option = styled.option<{ isSelected: boolean }>`
  background: ${white};
  color: ${({ isSelected }) => (isSelected ? skyBlue : lightBlack)};
  font-family: ${gillFont};
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 52px;
  :hover {
    background: ${skyBlue};
    color: ${white};
  }
`

const handleChange =
  (state: ReferUsFormType, onChange: (name: string, value: number | string | boolean) => void) =>
  (event: React.ChangeEvent<HTMLSelectElement>) => {
    onChange(event.target.name, event.target.value)
  }

const sourceAttributeOptions = [
  "Google",
  "Facebook",
  "Instagram",
  "LinkedIn",
  "Other social media",
  "Word of mouth",
  "Referred by a friend of colleague",
  "Blog post",
  "Press or newspapers",
]

interface SourceFieldProps {
  state: ReferUsFormType
  onChange: (name: string, value: number | string | boolean) => void
  validate: boolean
}

const SourceField = ({ state, onChange, validate }: SourceFieldProps) => {
  const isValid = validate ? SourceField.isValid(state) : true
  return (
    <Container>
      <Select
        onChange={handleChange(state, onChange)}
        value={state.source}
        name="source"
        valid={isValid}
      >
        <Option value="" disabled selected>
          How did you hear about us?
        </Option>
        {sourceAttributeOptions.map(option => (
          <Option isSelected={option.toLowerCase() === state.source} value={option.toLowerCase()}>
            {option}
          </Option>
        ))}
      </Select>
    </Container>
  )
}

SourceField.isValid = ({ source }: ReferUsFormType) => {
  return Boolean(source)
}

export default SourceField
