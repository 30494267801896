import * as React from "react"
import OutlinedInput from "../../../../components/form/OutlinedInput"
import FormFieldProps from "../../../../components/form/FormFieldProps"
import ReferUsFormType from "../../../../model/ReferUsFormType"

const FirstNameField = ({ onChange, onBlur, state, validate }: FormFieldProps<ReferUsFormType>) => {
  return (
    <OutlinedInput
      name="firstName"
      onChange={onChange}
      value={state.firstName}
      label="Your first name"
      validate={validate}
      onBlur={onBlur}
      type="text"
      isValid={FirstNameField.isValid(state)}
      marginBottom="30px"
    />
  )
}

FirstNameField.isValid = ({ firstName }: ReferUsFormType) => {
  return Boolean(firstName)
}

export default FirstNameField
