import * as React from "react"
import OutlinedInput from "../../../../components/form/OutlinedInput"
import FormFieldProps from "../../../../components/form/FormFieldProps"
import { validEmailFormat } from "../../../../constants/validation"
import ReferUsFormType from "../../../../model/ReferUsFormType"

const EmailField = ({ onChange, onBlur, state, validate }: FormFieldProps<ReferUsFormType>) => {
  return (
    <OutlinedInput
      name="email"
      onChange={onChange}
      value={state.email}
      label="Your email address"
      validate={validate}
      onBlur={onBlur}
      type="text"
      isValid={EmailField.isValid(state)}
      marginBottom="30px"
    />
  )
}

EmailField.isValid = ({ email }: ReferUsFormType) => {
  return email && validEmailFormat.test(email)
}

export default EmailField
