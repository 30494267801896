import * as React from "react"
import OutlinedInput from "../../OutlinedInput"
import FormFieldProps from "../../FormFieldProps"
import { validEmailFormat } from "../../../../constants/validation"
import ReferUsFormType from "../../../../model/ReferUsFormType"

const TheirEmailField = ({
  onChange,
  onBlur,
  state,
  validate,
}: FormFieldProps<ReferUsFormType>) => {
  return (
    <OutlinedInput
      name="theirEmail"
      onChange={onChange}
      value={state.theirEmail}
      label="Their email address"
      validate={validate}
      onBlur={onBlur}
      type="text"
      isValid={TheirEmailField.isValid(state)}
      marginBottom="30px"
    />
  )
}

TheirEmailField.isValid = ({ theirEmail }: ReferUsFormType) => {
  return theirEmail && validEmailFormat.test(theirEmail)
}

export default TheirEmailField
