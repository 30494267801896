import * as React from "react"
import OutlinedInput from "../../../../components/form/OutlinedInput"
import FormFieldProps from "../../../../components/form/FormFieldProps"
import ReferUsFormType from "../../../../model/ReferUsFormType"

const ReferralNameField = ({
  onChange,
  onBlur,
  state,
  validate,
}: FormFieldProps<ReferUsFormType>) => {
  return (
    <OutlinedInput
      name="referralName"
      onChange={onChange}
      value={state.referralName}
      label="Name of referral"
      validate={validate}
      onBlur={onBlur}
      type="text"
      isValid={ReferralNameField.isValid(state)}
      marginBottom="30px"
    />
  )
}

ReferralNameField.isValid = ({ referralName }: ReferUsFormType) => {
  return Boolean(referralName)
}

export default ReferralNameField
