import * as React from "react"
import OutlinedInput from "../../OutlinedInput"
import FormFieldProps from "../../FormFieldProps"
import { validUrl } from "../../../../constants/validation"
import ReferUsFormType from "../../../../model/ReferUsFormType"

const WebsiteField = ({ onChange, onBlur, state, validate }: FormFieldProps<ReferUsFormType>) => {
  return (
    <OutlinedInput
      name="website"
      onChange={onChange}
      value={state.website}
      label="Your company’s website"
      validate={validate}
      onBlur={onBlur}
      type="text"
      isValid={WebsiteField.isValid(state)}
      marginBottom="30px"
    />
  )
}

WebsiteField.isValid = ({ website }: ReferUsFormType) => {
  return website && validUrl.test(website)
}

export default WebsiteField
