import React from "react"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../../../components/Layout"
import SEO from "../../../components/Seo"
import { black, grey, skyBlue, white } from "../../constants/colors"
import { desktop, largeDesktop, smallMobile } from "../../constants/screenSizes"
import BorderedButton from "../../components/button/BorderedButton"
import ColorWrapper from "../../components/landing-page/ColorWrapper"
import staticBackgroundStyles from "../../components/landing-page/staticBackgroundStyles"
import Block from "../../components/landing-page/Block"
import InnerBlock from "../../components/landing-page/InnerBlock"
import Part from "../../components/landing-page/Part"
import Title from "../../components/landing-page/Title"
import LargeTitle from "../../components/landing-page/LargeTitle"
import Subheading from "../../components/landing-page/Subheading"
import Description from "../../components/landing-page/Description"
import Spinner from "../../components/Spinner"
import { post } from "../../../api/general"
import ReferUsForm, {
  DEFAULT_REFER_TO_US_FORM_STATE,
} from "../../components/form/refer-us-form/ReferUsForm"
import ReferUsFormType from "../../model/ReferUsFormType"
import IconFood from "../../../svg/icon-food.inline.svg"
import IconTeamGifting from "../../../svg/icon-team-gifting.inline.svg"
import IconVirtualExperiences from "../../../svg/icon-virtual-experiences.inline.svg"
import IconMovingInGifts from "../../../svg/icon-movingin-gifts.inline.svg"
import IconChristmasHampers from "../../../svg/icon-christmas-hampers.inline.svg"
import IconNewJoinerGifts from "../../../svg/icon-newjoinergifts.inline.svg"
import IconOfficeSupplies from "../../../svg/icon-office-supplies.inline.svg"
import IconBrandedMerch from "../../../svg/icon-branded-merch.inline.svg"
import InfoCarousel from "../../components/carousel/InfoCarousel"

const IconsBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 50px;
  @media (max-width: ${desktop}px) {
    margin-top: 40px;
  }
`

const IconsPart = styled.div<{ margin?: string; alignSelf: string }>`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 895px;
  margin: ${({ margin }) => margin || 0};
  align-self: ${({ alignSelf }) => alignSelf};
  @media (max-width: ${largeDesktop}px) {
    margin: 0;
    &:last-child {
      margin-top: 45px;
    }
  }
  @media (max-width: ${smallMobile}px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    row-gap: 60px;
    &:last-child {
      margin-top: 60px;
    }
  }
`

const IconContainer = styled.div`
  width: 190px;
  height: 190px;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-self: center;
  @media (max-width: ${desktop}px) {
    width: 150px;
    height: 150px;
    svg {
      max-height: 135px;
    }
  }
  @media (max-width: ${smallMobile}px) {
    width: unset;
    height: unset;
  }
`

const VoucherGrid = styled.div`
  margin-top: 60px;
  width: 100%;
  display: grid;
  grid-template-columns: 525px 1fr;
  column-gap: 30px;
  row-gap: 60px;
  justify-content: space-between;
  align-items: flex-start;
  @media (max-width: ${desktop}px) {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
  }
`

const SuccessMessage = styled.div`
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const TermsContainer = styled.div`
  background-color: ${skyBlue};
  padding: 40px 45px;
  width: 100%;
  max-width: 570px;
  border-radius: 5px;
  justify-self: flex-end;
  @media (max-width: ${desktop}px) {
    justify-self: center;
  }
  @media (max-width: ${smallMobile}px) {
    padding: 60px 30px;
  }
`

const TermsList = styled.ul`
  margin-top: 20px;
  margin-bottom: 0;
`

const TermsItem = styled.li`
  padding-left: 15px;
  color: ${white};
  font-size: 18px;
  line-height: 32px;
  letter-spacing: 0.8px;
`

const icons = [
  IconFood,
  IconTeamGifting,
  IconVirtualExperiences,
  IconMovingInGifts,
  IconChristmasHampers,
  IconNewJoinerGifts,
  IconOfficeSupplies,
  IconBrandedMerch,
]

interface Props {}

const ReferUsToYourCompanyLandingPage: React.FC<Props> = (): JSX.Element => {
  const [isValid, setIsValid] = React.useState<boolean>(true)
  const [referUsFormState, setReferUsFormStateState] = React.useState<ReferUsFormType>(
    DEFAULT_REFER_TO_US_FORM_STATE
  )
  const [validate, setValidate] = React.useState<boolean>(false)
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [isSuccessful, setIsSuccessful] = React.useState<boolean>(false)

  const formRef = React.createRef()

  const handleScrollToForm = () =>
    formRef.current.scrollIntoView({
      behavior: "smooth",
    })

  const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    const isValid = ReferUsForm.isValid(referUsFormState)
    if (isValid) {
      setIsLoading(true)
      await post(
        "https://uat.socialsupermarket.org/wp-json/social-supermarket/v1/refer/refer-us",
        referUsFormState
      )
      setIsLoading(false)
      setIsSuccessful(true)
    } else {
      setIsValid(false)
      setValidate(true)
    }
  }

  return (
    <Layout fullWidth>
      <SEO title="Refer us to your company" description="Refer us to your company" />
      <ColorWrapper>
        <StaticImage
          style={staticBackgroundStyles}
          src="../../../images/refer-us-masthead.jpg"
          alt="Masthead background image"
        />

        <Block>
          <Part width="600px" padding="0 0 0 65px" wrapPadding="0 0 50px 0">
            <LargeTitle color={white} padding="0 0 40px 0">
              Refer us to your company and claim your £150 voucher
            </LargeTitle>
            <BorderedButton secondaryColor={black} onClick={handleScrollToForm}>
              TAKE ME THERE
            </BorderedButton>
          </Part>
        </Block>
      </ColorWrapper>
      <ColorWrapper background={grey}>
        <Block>
          <Part width="795px" padding="0 0 0 65px" wrapPadding="0 0 0 0">
            <Subheading isBordered borderColor={skyBlue}>
              Who are social supermarket?
            </Subheading>
            <Title margin="30px 0 0 0">
              We are the UK’s <b>leading social enterprise marketplace</b>
            </Title>
            <Description margin="30px 0 40px 0">
              Every brand that we work with has a social or environmental mission. That means they
              create net positive impact for people and planet. When you purchase from us, you’re
              also playing your part to help the world. Here’s a taste of some of the brands we work
              with:
            </Description>
            <BorderedButton color={skyBlue} secondaryColor={white} icon="arrow right" to="/about">
              FIND OUT MORE
            </BorderedButton>
          </Part>
        </Block>
        <InfoCarousel />
      </ColorWrapper>

      <Block>
        <InnerBlock>
          <Part width="736px">
            <Title>Our vision is a world where every purchase does good</Title>
            <Description margin="26px 0 0 0" tabletMargin="20px 0 0 0">
              Everything from Christmas gifts for the team to the branded merch at your next company
              event, the snacks, teas and coffees stocked in your office kitchens or the team
              building experience for your next get-together could come from a social enterprise.
              That means that your company can both get a unique product or service, while giving
              back to good causes. Here are some currently available:
            </Description>
          </Part>
          <IconsBlock>
            <IconsPart alignSelf="flex-start" margin="0 0 0 35px">
              {icons.slice(0, 4).map(Icon => (
                <IconContainer>
                  <Icon />
                </IconContainer>
              ))}
            </IconsPart>
            <IconsPart alignSelf="flex-end" margin="45px 35px 0 0">
              {icons.slice(4, 8).map(Icon => (
                <IconContainer>
                  <Icon />
                </IconContainer>
              ))}
            </IconsPart>
          </IconsBlock>
        </InnerBlock>
      </Block>

      <ColorWrapper background={grey}>
        <Spinner isLoading={isLoading} />

        <Block>
          <div ref={formRef}>
            <InnerBlock padding="0 0 0 65px">
              <Part width="735px">
                <LargeTitle>Get your £150 voucher</LargeTitle>
                <Description margin="45px 0 0 0" tabletMargin="20px 0 0 0">
                  Simply fill in the below form to send an email to the most relevant person at your
                  company letting them know that you think their next gift or procurement spend
                  should be with brands doing good in the world. If they go on to place an order of
                  at least £2,000 within 3 calendar months of your referral, we will send you a £150
                  voucher to use on Social Supermarket.
                </Description>
              </Part>
              <VoucherGrid>
                {isSuccessful ? (
                  <SuccessMessage>
                    Thank you for your enquiry, we will endeavor to respond asap.
                  </SuccessMessage>
                ) : (
                  <ReferUsForm
                    isValid={isValid}
                    onSubmit={handleSubmit}
                    onChange={(name, value) =>
                      setReferUsFormStateState(prev => ({ ...prev, [name]: value }))
                    }
                    state={referUsFormState}
                    validate={validate}
                  />
                )}
                <TermsContainer>
                  <Subheading isBordered color={white} borderColor={white}>
                    Terms & Conditions
                  </Subheading>
                  <TermsList>
                    <TermsItem>
                      Gift voucher will be sent to the email you provide in the above form.
                    </TermsItem>
                    <TermsItem>Your gift voucher will be valid for 12 months from issue.</TermsItem>
                    <TermsItem>
                      Should two or more employees refer the same company within the same time
                      period, we will send £150 vouchers for every £2,000 spent by your company with
                      us on a first referred, first rewarded basis.
                    </TermsItem>
                  </TermsList>
                </TermsContainer>
              </VoucherGrid>
            </InnerBlock>
          </div>
        </Block>
      </ColorWrapper>
    </Layout>
  )
}

export default ReferUsToYourCompanyLandingPage
